import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import Papa from "papaparse";

import "./Admin.css";
import "./EditRow.css";
import "./ImportData.css";
import "./EditSpeakers.css";
import "./UploadAudio.css";

import Input from "../input/Input";

import { BiPlus } from "react-icons/bi";
import { BsFileX, BsSearch } from "react-icons/bs";

const tabState = {};

// /admin - row
// /admin/import - import
// /admin/speakers - speakers

function CreateTable({ wordData, setWordData }) {
    const [ rowData, setRowData ] = useState(null);

    const inputRefs = useRef([]);

    useEffect(() => {
        const newRowData = [];
        for (const { type, data } of wordData.row.content)
            newRowData.push({
                type: type,
                data: data || ""
            });

        setRowData(newRowData);
    }, [wordData]);

    useEffect(() => {
        if (rowData === null) return;
        inputRefs.current = inputRefs.current.slice(0, rowData.length);

        for (const input of inputRefs.current) {
            input.style.width = "0px";
            input.style.width = `${input.scrollWidth}px`;
        }
    }, [rowData]);



    const inputChangeHandler = (type, value) => {
        const newRowData = [...rowData];
        newRowData.find((cell) => cell.type === type).data = value;
        setRowData(newRowData);
    }

    const deleteClickHandler = () => {
        console.log({
            metadata: wordData.metadata,
            id: wordData.row.id
        });

        axios.post("/admin/row/delete", {
            metadata: wordData.metadata,
            id: wordData.row.id
        }).then(() => setWordData(null));
    }

    const cancleClickHandler = () => {
        const newRowData = [];
        for (const { type, data } of wordData.row.content)
            newRowData.push({
                type: type,
                data: data || ""
            });

        setRowData(newRowData);
    }

    const saveClickHandler = () => {
        const reqObj = {
            metadata: wordData.metadata,
            id: wordData.row.id
        };
        for (const { type, data } of rowData)
            reqObj[type] = data;

        axios.post("/admin/row", reqObj)
            .then(() => {
                const newWordData = {...wordData, row: {}};
                newWordData.row.id = reqObj.id;
                newWordData.row.content = [];
                for (const cell of rowData)
                    newWordData.row.content.push(cell);

                setWordData(newWordData);
            });

        
    };

    if (rowData === null) return undefined;

    return (
        <>
            <div className="tbl-wraper">
                <table>
                    <thead>
                        { wordData.headers.map((row, i) => {
                            return (
                                <tr key={ i }>
                                    { row.cells.map(({ size, content }, i) => {
                                        return (
                                            <td
                                                key={ i }
                                                colSpan={ size }
                                                dangerouslySetInnerHTML={{ __html: content }}
                                            ></td>
                                        );
                                    }) }
                                </tr>
                            );
                        }) } 
                    </thead>

                    <tbody>
                        <tr>
                            { rowData.map(({ type, data }, index) => {
                                return (
                                    <td key={ type }>
                                        <input
                                            value={ data }
                                            ref={ elem => inputRefs.current[index] = elem }
                                            onChange={ ({target}) => inputChangeHandler(type, target.value) }
                                        />
                                    </td>
                                );
                            }) }
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="b-btns">
                <div
                    className="btn"
                    onClick={ () => deleteClickHandler() }
                >
                    delete
                </div>

                <div
                    className="btn"
                    onClick={ () => cancleClickHandler() }
                >
                    reset
                </div>

                <div
                    className="btn"
                    onClick={ () => saveClickHandler() }
                >
                    save
                </div>
            </div>
        </>
    );
}

export function EditRow() {
    const [ search, setSearch ] = useState("");
    
    const [ wordData, setWordData ] = useState(null);
    const [ idError, setIdError ] = useState({ status: null, id: null });

    useEffect(() => {
        tabState.set("row");
    });

    const searchByWord = (id) => {
        axios.post("/admin/row/search", { id })
            .then(res => {
                setWordData(res.data);
                setIdError({ status: false, id: null });
            })
            .catch(err => {
                setIdError({ status: true, id: search });
                setWordData(null);
            });
    }

    return (
        <div className="edit-row">
            <div className="b-search">
                <input
                    placeholder="Enter word id"
                    value={ search }
                    onChange={ ({ target }) => setSearch(target.value) }
                />

                <div
                    className="b-search-btn"
                    onClick={ () => searchByWord(search) }
                >
                    <BsSearch />
                </div>
            </div>

            { idError.status
            ?   <div className="b-error">{ `No results were found for id "${idError.id}".` }</div>
            :   undefined
            }

            { wordData !== null
            ?   <CreateTable wordData={ wordData } setWordData={ setWordData } />
            :   undefined
            }
        </div>
    );
}

export function ImportData() { 
    const [ tableType, setTableType ] = useState("nouns");
    const [ file, setFile ] = useState(null);
    const [ uploadStatus, setUploadStatus ] = useState(null);

    useEffect(() => {
        tabState.set("import");
    });



    const uploadFileHandler = (target) => {
        setFile(target.files[0]);
        setUploadStatus(null);
    }

    const importClickHandler = () => {
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                axios.post("/admin/import", {
                    table_name: tableType,
                    data: result.data
                }).then(() => setUploadStatus("success"))
                .catch(() => setUploadStatus("failed"));
            }
        });
    }

    

    return (
        <div className="import-data">
            <span className="manual-text">
                Select the name of the table in which you want to import new and (or) update an existed data. 
                Then press "UPLOAD" and choose the file to upload. Then press "IMPORT".
            </span>

            <div className="b-options">
                <select
                    onChange={ ({ target }) => setTableType(target.value) }
                >
                    <option value="nouns">Nouns</option>
                    <option value="verbs">Verbs</option>
                    <option value="adjectives">Adjectives</option>
                    <option value="other">Other</option>
                </select>

                <label>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={ ({ target }) => uploadFileHandler(target) }
                    />

                    <div className="button">upload</div>
                    <span className="file-name">{ file !== null ? file.name : "No file chosen" }</span>
                </label>
            </div>

            <div className="b-options">
                <div
                    className={ `button ${ !file ? "hidden" : "" }` }
                    onClick={ () => importClickHandler() }
                >
                    import
                </div>

                <span className="download-status">
                    { uploadStatus === "success"
                    ?   "Upload successfully"
                    :   uploadStatus === "failed"
                        ? "Upload failed"
                        : ""
                    }
                </span>
            </div>
        </div>
    );
}

function Speaker({ id, pos, code, dialect, desc, startEdit, data, setData }) {
    const [ edit, setEdit ] = useState(false);

    const [ sCode,    setSCode ] = useState(code);
    const [ sDialect, setSDialect ] = useState(dialect);
    const [ sDesc,    setSDesc ] = useState(desc);

    const descRef = useRef(null);

    useEffect(() => {
        descRef.current.value = desc;
        setEdit(startEdit);
    }, []);

    return (
        <div className="b-speaker">
            <div className="b-speaker-header">
                <span>{ "Speaker " }
                    { pos }
                    { " (speaker’s code is " }
                    { edit
                    ?   <Input
                            value={ sCode }
                            setState={ setSCode }
                            disabled={ !edit }
                        />
                    :   sCode
                    }
                    { ")." }
                </span>

                <span className="dialect">
                    { edit
                    ?    <Input
                            value={ sDialect }
                            setState={ setSDialect }
                            disabled={ !edit }
                        />
                    :   sDialect
                    }
                </span>
            </div>
            <div className="b-speaker-desc">
                <textarea
                    ref={ descRef }
                    onChange={ ({ target }) => setSDesc(target.value) }
                    disabled={ !edit }
                />
            </div>
            <div className="b-speaker-footer">
                { edit
                ? <>
                    <div
                        className="button"
                        onClick={ () => {
                            setEdit(false);
                            axios.delete("/admin/speakers", { data: { id: id }})
                                .then(() => setData(data.filter((elem) => elem.id !== id)));
                        } }
                    >
                        delete
                    </div>
                    <div
                        className="button"
                        onClick={ () => {
                            setEdit(false);
                            axios.put("/admin/speakers", {
                                id: id,
                                code: sCode,
                                data: sDesc,
                                dialect: sDialect
                            });
                        } }
                    >
                        save
                    </div>
                  </>
                : <div
                    className="button"
                    onClick={ () => setEdit(true) }
                  >
                    edit
                  </div>
                }
            </div>
        </div>
    );
}

export function EditSpeakers() {
    const [ data, setData ] = useState(null);

    useEffect(() => {
        tabState.set("speakers");

        axios.get("/speakers").then((res) => {
            console.log(res.data);

            const data = res.data;
            data.forEach((elem) => {
                elem.edit = false;
            });
            setData(data);
        });
    }, []);

    if (data === null) return undefined;

    return (
        <div className="sec-edit-speakers">
            <div className="b-edit-speakers">
                { data.map((elem) => {
                    return (
                        <Speaker
                            id={ elem.id }
                            key={ elem.id }
                            pos={ elem.position }
                            code={ elem.code }
                            dialect={ elem.dialect }
                            desc={ elem.data }
                            startEdit={ elem.edit }
                            data={ data }
                            setData={ setData }
                        />
                    );
                }) }
                <div
                    className="b-add-speaker"
                    onClick={ () => {
                        axios.get("/admin/speakers")
                            .then((res) => {
                                const newData = data.slice();
                                newData.push({
                                    id: res.data.id,
                                    position: res.data.position,
                                    code: "",
                                    dialect: "",
                                    data: "",
                                    edit: true
                                });
                                setData(newData);
                            });
                    } }
                >
                    <BiPlus />
                </div>
            </div>
        </div>
    );
}

export function UploadAudio() {
    const [ drag, setDrag ] = useState(false);
    const [ files, setFiles ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        tabState.set("audio");
    }, []);


    const dragStartHandler = (e) => {
        e.preventDefault();
        setDrag(true);
    }

    const dragLeaveHandler = (e) => {
        e.preventDefault();
        setDrag(false);
    }

    const dropHandler = (e) => {
        e.preventDefault();
        setFiles([...e.dataTransfer.files]);
        setDrag(false);
    }

    const uploadClickHandler = () => {
        setLoading(true);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++)
            formData.append(`file_${i}`, files[i]);

        axios.post("/admin/import/audio", formData,
            { headers: { "Content-Type": "multipart/form-data" } }
        ).then(() => {
            setFiles([]);
            setLoading(false);
        });
    }

    return (
        <div className="upload-audio">
            <div
                className={ `b-drag ${ drag ? "over" : "" }` }
                onDragStart={ (e) => dragStartHandler(e) }
                onDragLeave={ (e) => dragLeaveHandler(e) }
                onDragOver={ (e) => dragStartHandler(e) }
                onDrop={ (e) => dropHandler(e) }
            >
                { files.length
                ?   <>
                        <span>to send files to the server, click on "upload"</span>
                        <div
                            className={ `button ${loading ? "disabled" : ""}` }
                            onClick={ () => uploadClickHandler() }
                        >
                            { loading
                            ?   "loading"
                            :   "upload"
                            }
                        </div>
                    </>
                :   <span>
                        { drag
                        ?   "drop the files to upload them"
                        :   "drag files to this area to upload them"
                        }
                    </span>
                }
            </div>
        </div>
    );
}

export default function Admin() {
    const [ tab, setTab ] = useState(null);
    tabState.get = tab;
    tabState.set = setTab;

    return (
        <div className="p-admin">
            <nav>
                <Link
                    className={ tab === "row"
                    ? "item active"
                    : "item"
                    }
                    to="/dictionary/admin"
                >
                    <span>edit rows</span>
                </Link>
                <Link
                    className={ tab === "import"
                    ? "item active"
                    : "item"
                    }
                    to="/dictionary/admin/import"
                >
                    <span>import data</span>
                </Link>
                <Link
                    className={ tab === "speakers"
                    ? "item active"
                    : "item"
                    }
                    to="/dictionary/admin/speakers"
                >
                    <span>edit speakers</span>
                </Link>
                <Link
                    className={ tab === "audio"
                    ? "item active"
                    : "item"
                    }
                    to="/dictionary/admin/audio"
                >
                    <span>upload audio</span>
                </Link>
                <Link
                    className="item"
                    to="/dictionary"
                >
                    <span>to the main page</span>
                </Link>
            </nav>

            <section>
                <Outlet />
            </section>
        </div>
    );
}
