import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

axios.defaults.baseURL = "https://nena-dict.ru:8000";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<App />
);

