import './Tbody.css';

import Audio from "../audio/Audio";

export default function Tbody({ onChange: filtersState, rows }) {
    const audioButton = document.createElement("audio");

    function Row({ row }) {
        function Cell({ cell }) {
            return (
                <td
                    colSpan={ cell.size !== null ? cell.size : 1 }
                    type={ cell.type !== null ? cell.type : undefined }
                >
                    <div
                        className={ [cell.type] in filtersState && !filtersState[cell.type].isActive
                        ? "td-child hidden"
                        : "td-child" 
                        }
                    >
                        { cell.content !== null
                        ? <span dangerouslySetInnerHTML={{ __html: cell.content }}></span>
                        : <span>{ "none" }</span>
                        }

                        { cell.link !== null
                        ? <Audio link={ cell.link } audioButton={ audioButton } />
                        : undefined
                        }
                    </div>
                </td>
            );
        }

        return (
            <tr>
                { row.cells.map( (cell, index) => (
                    <Cell cell={ cell } key={ index } />
                )) }
            </tr>
        );
    }

    return (
        <tbody>
            { rows.map( (row, index) => (
                <Row row={ row } key={ index } />
            )) }
        </tbody>
    );
}