import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import './TableModule.css';

import Table from "../table/Table";
import Filters from "../filters/Filters";
import Pages from "../pages/Pages";

export default function TableModule() {
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ utilsData, setUtilsData ] = useState(null);

    const navigate = useNavigate();
    const tableType = useParams().type;

    const location = useLocation();
    const queryParams = location.search
        .substring(1).split("&")
        .reduce((result, elem) => {
            const arr = elem.split("=");

            if (elem === "") return result;
            return { ...result, [arr[0]]: arr[1] }; 
    }, {});

    if (!Object.keys(queryParams).length) {
        queryParams.get = "rows";
        queryParams.sort = "id";
        queryParams.page = "1";
    }

    useEffect(() => {
        setIsLoaded(false);

        axios.get(`/table/${tableType}?get=utils`)
            .then((res) => {
                res.data.filters = createFiltersState(res.data.filters);
                setUtilsData(res.data);
                setIsLoaded(true);
            }).catch((err) => {
                navigate(`/notFound`);
            });
    }, [tableType]);
    

    function createFiltersState(filters) {
        const obj = {};
        for (const filter of filters)
            obj[filter.type] = { ...filter, isActive: false };
        
        return obj;
    }

    function changeFilterActive(type) {
        const newState = {...utilsData};
        const filters = {...newState.filters};

        filters[type].isActive = !filters[type].isActive;
        newState.filters = filters;

        setUtilsData(newState);
    }

    if (isLoaded === false)
        return undefined;
    
    return (
        <div className="table-module">
            <div className="table-settings-top">
                <div className="table-setting">
                    { Object.keys(utilsData.filters).length !== 0
                    && <Filters onPress={ changeFilterActive } data={ utilsData.filters }/>
                    }
                </div>

                <div className="table-setting">
                    <a
                        className="download-csv"
                        href={ `https://nena-dict.ru:8000/export?table=${tableType}` }
                    >
                        Export to CSV
                    </a>
                </div>

                <div className="table-setting">
                    <Pages pages={ utilsData.pages } queryParams={ queryParams } />
                </div>
            </div>

            <Table utilsData={ utilsData } queryParams={ queryParams } />

            <div className="table-settings-bottom">
                <div className="table-setting">
                    <Pages pages={ utilsData.pages } queryParams={ queryParams } />
                </div>
            </div>
        </div>
    );
}