import './Footer.css';

export default function Footer() {
    return (
        <footer>
            <div className="logo-wraper">
                <a href="https://iling.spb.ru/"><img src="/resource/images/ILI.png" /></a>
            </div>
            <div className="text-wraper">Supported by a grant from the Russian Foundation for Basic Research (#20-012-00312a)</div>
            <div className="logo-wraper">
                <a href="https://iling.spb.ru/departements/typology/about.html.ru "><img src="/resource/images/LTIJa.png" /></a>
            </div>
        </footer>
    );
}