import "./Team.css";

function ProfileObj(img_url, href, name, desc) {
    const obj = {};

    obj.img_url = `/resource/images/${img_url}`;
    obj.href = href;
    obj.name = name;
    obj.desc = desc;

    return obj;
}

const team = [
    ProfileObj("maria.png", "https://iling.spb.ru/persons/ovsyannikova-mariya-aleksandrovna", "Maria Ovsjannikova", "Dictionary design, data processing"),
    ProfileObj("sergey.png", "https://iling.spb.ru/persons/say-sergey-sergeevich", "Sergey Say", "Dictionary design, data processing"),
    ProfileObj("elizaveta.png", "https://iling.spb.ru/persons/zabelina-elizaveta-aleksandrovna", "Elizaveta Zabelina", "Dictionary design, data processing"),
    ProfileObj("elena.png", "https://www.hse.ru/org/persons/595441865", "Elena Shvedova", "Data processing"),
    ProfileObj("valeria.png", "https://www.researchgate.net/profile/Valeria-Modina", "Valeria Modina", "Data processing"),
    ProfileObj("matvei.png", "https://spbu.academia.edu/MatveiLopatin", "Matvei Lopatin", "Data processing"),
    ProfileObj("daria.png", "https://vk.com/id182211100", "Daria Ermakova", "Sound files management")
];

function Profile({ data }) {
    return (
        <a className="profile" href={ data.href }>
            <img src={ data.img_url } />
            <span className="name">{ data.name }</span>
            <span className="desc">{ data.desc }</span>
        </a>
    );
}

export default function Team() {
    return (
        <div className="team-page">
            <div className="team-page-header">
                <span>Team</span>
            </div>

            <div className="team-page-content">
                <div className="profile-wraper">
                    { team.map((member, index) => <Profile key={ index } data={ member } />) }
                </div>
                <div className="team-description">
                    <h1>Acknowledgments</h1>
                    <p>
                        Collecting and annotating the data for this dictionary has greatly benefited from the collaboration with our friends and colleagues who worked on different aspects of NENA during our memorable field trip to Urmiya in August 2021: Natalia Logvinova, Alexey Lyavdansky, Alina Russkikh, Ivan Sarkisov and Varvara Shuvalova.<br /><br />
                        Our team is also grateful to <a href="https://t.me/cherpatel" target="_blank">Vladimir Kochkin</a> and <a href="https://t.me/markprudnikov" target="_blank">Mark Prudnikov</a> for developing the website.
                    </p>
                </div>
            </div>
        </div>
    );
}