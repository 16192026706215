import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsFillTriangleFill } from "react-icons/bs";

import './NavItem.css';

export default function NavItem({ elementData }) {
    const [ menuIsOpen, setMenuIsOpen ] = useState(false);

    return (
        <div className={ menuIsOpen ? "nav-item click" : "nav-item" }>
            { elementData.context === null
            ?   <Link className="nav-item-text" to={ elementData.link }>{ elementData.name }</Link>
            :   <div
                    className="nav-item-text"
                    onClick={ () => setMenuIsOpen(!menuIsOpen) }
                >
                    { elementData.name }
                    <div
                        className={ menuIsOpen ? "nav-item-arrow rotate" : "nav-item-arrow" }
                    >
                        <BsFillTriangleFill />
                    </div>
                </div>
            }

            { elementData.context !== null
            &&   <div className={ menuIsOpen ? "nav-item-menu" : "nav-item-menu hidden" }>
                    {elementData.context.map((elem, index) => (
                        <Link
                            className="nav-item-menu-item"
                            to={ elem.link }
                            key={ "MenuItem"+index }
                            onClick={ () => setMenuIsOpen(false) }
                        > { elem.name }
                        </Link>
                    ))}
                </div>
            }
        </div>
    );
}