import { useState } from "react";
import Cookie from "js-cookie";

export function useAuth() {
    const [ state, setState ] = useState(false);

    function checkCookie() {
        if (Cookie.get("token") !== undefined)
            setState(true);
        else
            setState(false);
    }

    return [ state, checkCookie ];
}