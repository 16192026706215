import { Outlet } from "react-router-dom";

import './CorpusModule.css';

import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function CorpusModule() {
    return (
        <main>
            <Header title={ "Corpus of NENA varieties\nspoken in Russia" } path={ "/corpus" } />
            <Outlet />
            <Footer />
        </main>
    );
}