import { Link } from 'react-router-dom';

import './Header.css';

export default function Header({ title, path }) {
    return (
        <header>
            <Link to={ path }>{ title }</Link>
        </header>
    );
}