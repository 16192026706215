import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import './Table.css';

import Thead from '../thead/Thead';
import Tbody from '../tbody/Tbody';

export default function Table({ utilsData, queryParams }) {
    const [ tbodyData, setTbodyData ] = useState(null);

    const tableType = useParams().type;
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        document.querySelector("html").scrollTop = 0;

        axios.get(`/table/${tableType}${getQueryParams()}`)
            .then((res) => setTbodyData(res.data))
            .catch(() => navigate(`/notFound`));
    }, [ location ]);

    function getQueryParams() {
        let queryStr = "?";
        for (const key in queryParams)
            queryStr += `${key}=${queryParams[key]}&`;
        
        return queryStr.substring(0, queryStr.length - 1);
    }

    if (tbodyData === null)
        return undefined;

    return (
        <div className="table-wraper">
            <table>
                <Thead onChange={ utilsData.filters } headers={ utilsData.headers } currentSort={ queryParams.sort || "id" } />

                <Tbody onChange={ utilsData.filters } rows={ tbodyData.rows } />
            </table>
        </div>
    );
}