import "./Filters.css";

function Filter({ onPress, data }) {
    return (
        <div className="settings-bar-cell">
            <div className="name">{ data.name }</div>
            <label className="switch">
                <input onClick={ () => onPress(data.type) } type="checkbox" defaultChecked={ data.isActive } />
                <div className="slider"></div>
            </label>
        </div>
    );
}

export default function Filters({ data, onPress }) {
    return (
        <div className="settings-bar">
            <div className="settings-bar-text">Show:</div>

            { Object.keys(data).map( key => (
                <Filter onPress={ onPress } key={ key } data={ data[key] } />
            )) }
        </div>
    );
}