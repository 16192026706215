import React from 'react';
import { Link } from 'react-router-dom';
import { BsFillTriangleFill } from "react-icons/bs";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import "./Pages.css";

function createPagesArray(pages, { page: currentPage, sort }) {
    const arrayResult = [];

    const pagesArr = (new Array(pages)).fill(0);
    pagesArr[0] = 1; // first page
    pagesArr[+currentPage - 2] = 1; // previous page
    pagesArr[+currentPage - 1] = 1; // current page
    pagesArr[+currentPage] = 1; // next page
    pagesArr[+pages - 1] = 1; // last page

    let zeroFlag = false;
    for (let index = 0; index < pages; index++) {
        if (pagesArr[index] === 1) {
            arrayResult.push(
                <Link
                    className={ index + 1 === +currentPage
                    ? "pages-bar-block disabled"
                    : "pages-bar-block"
                    }
                    to={ `?get=rows&sort=${ sort }&page=${ index + 1 }`}
                >
                    { index + 1 }
                </Link>
            );

            zeroFlag = false;
        } else if (pagesArr[index] === 0) {
            if (zeroFlag === false) {
                arrayResult.push(
                    <div className="pages-bar-block pages-bar-gap">
                        <HiOutlineDotsHorizontal />
                    </div>
                );
            }

            zeroFlag = true;
        }
    }

    return arrayResult;
}

export default function Pages({ pages, queryParams }) {
    return (
        <div className="pages-bar">
            <Link
                className={ +queryParams.page === 1
                ? "pages-bar-block pages-bar-prev disabled"
                : "pages-bar-block pages-bar-prev"
                }
                to={ `?get=rows&sort=${ queryParams.sort }&page=${ +queryParams.page - 1 }`}
            >
                <BsFillTriangleFill />
            </Link>

            {
                createPagesArray(pages, queryParams).map((elem, index) => React.cloneElement(elem, {key: index}))
            }

            <Link
                className={ +queryParams.page === pages
                ? "pages-bar-block pages-bar-next disabled"
                : "pages-bar-block pages-bar-next"
                }
                to={ `?get=rows&sort=${ queryParams.sort }&page=${ +queryParams.page + 1 }`}
            >
                <BsFillTriangleFill />
            </Link>
        </div>
    );
}