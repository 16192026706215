import axios from "axios";
import { useState, useEffect, useLayoutEffect, createContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from "./userHooks.jsx";

import './App.css';

import Admin, {
    EditRow,
    ImportData,
    EditSpeakers,
    UploadAudio
} from "./components/admin/Admin";
import Login from "./components/login/Login";
import Main from "./components/main/Main";
import General from "./components/general/General"
import TableModule from "./components/tableModule/TableModule";
import { DiagForms, SpellingConv, Speakers } from "./components/textpage/TextPage";
import Team from "./components/team/Team";
import CorpusModule from "./components/corpusModule/CorpusModule";
import Corpus from "./components/corpus/Corpus";
import MainPage, { MainInfo } from "./components/mainPage/MainPage";
import Page404 from "./components/page404/Page404";
import Developing from "./components/Developing/Developing";

export const Context = createContext(null);

function useWindowSize() {
    const [ size, setSize ] = useState([window.innerWidth, window.innerHeight]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

function App() {
    const [ isReady, setIsReady ] = useState(false);

    const [ isAuth, setIsAuth ] = useAuth();

    const [ width, height ] = useWindowSize();

    useEffect(() => {
        axios.get("/check").then(() => {
            setIsAuth();
            setIsReady(true);
        });
    }, []);

    if (!isReady) return undefined;

    if (width < 1024) {
        return (
            <div className="not-supported">
                The mobile version of the site is not supported.
            </div>
        );
    }

    return (
        <Context.Provider
            value={ {
                isAuth: isAuth,
                setIsAuth: setIsAuth
            } }
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/dictionary">
                        { isAuth
                        ? <Route path="admin" element={ <Admin /> }>
                            <Route path="" element={ <EditRow /> } />
                            <Route path="import" element={ <ImportData /> } />
                            <Route path="speakers" element={ <EditSpeakers /> } />
                            <Route path="audio" element={ <UploadAudio /> } />
                            <Route path="*" element={ <Navigate to="/admin" /> } />
                          </Route>
                        : undefined
                        }

                        <Route path="" element={ <Main /> }>
                            <Route path="" element={ <General /> } />
                            <Route path="table/:type" element={ <TableModule /> } />
                            <Route path="diagnostic_forms" element={ <DiagForms /> } />
                            <Route path="spelling_conventions" element={ <SpellingConv /> } />
                            <Route path="idioms_and_speakers" element={ <Speakers /> } />
                            <Route path="team" element={ <Team /> } />
                            <Route path="login" element={ isAuth ? <Navigate to="/dictionary/admin" /> : <Login /> } />
                            <Route path="*" element={ <Page404 /> } />
                        </Route>
                    </Route>

                    <Route path="/corpus" element={ <CorpusModule /> }>
                        <Route path="" element={ <Corpus /> } />
                    </Route>

                    <Route path="/" element={ <MainPage /> } >
                        <Route path="" element={ <MainInfo /> } />
                        <Route path="*" element={ <Page404 /> } />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Context.Provider>
    );
}

export default App;
