import './Nav.css';

import NavItem from '../navItem/NavItem';

function Context(name, link) {
    this.name = name;
    this.link = `/dictionary${link}`;
}

function Item(name, link, context = null) {
    this.name = name;
    this.link = `/dictionary${link}`;
    this.context = context;
}

const navigation = [
    new Item("Main", "/"),
    new Item("Dictionary", null, [
        new Context("All words", "/table/all_words"),
        new Context("Nouns", "/table/nouns"),
        new Context("Verbs", "/table/verbs"),
        new Context("Adjectives", "/table/adjectives"),
        new Context("Other", "/table/other")
    ]),
    new Item("Diagnostic forms", "/diagnostic_forms"),
    new Item("Spelling conventions", "/spelling_conventions"),
    new Item("Idioms and speakers", "/idioms_and_speakers"),
    new Item("Team", "/team")
];

export default function Nav() {
    return (
        <nav>
            {navigation.map((elem, index) => (
                <NavItem key={"NavItem"+index} elementData={elem} />
            ))}
        </nav>
    );
}