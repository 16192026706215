import { Link } from "react-router-dom";

import "./General.css";

const monthStr = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
];

export default function General() {
    const date = new Date();
    const day = date.getDate();
    const month = monthStr[date.getMonth()];
    const year = date.getFullYear();

    return (
        <div className="general-page">
            <div className="text">
                <p className="main-text paragraph1">
                    The sound dictionary includes the recordings of 460 words for each of the three major Northeastern 
                    Neo-Aramaic varieties spoken in the village of Urmiya in Krasnodar Krai, Russia. The dictionary 
                    includes the recording of each word in the basic form (see All words for the full list of basic forms), 
                    as well as diagnostic forms for the main parts of speech (see Nouns, Adjectives, Verbs, and Other forms).
                </p>

                <p className="main-text paragraph2">
                    The dictionary can be used as a tool for comparative analysis of phonetic and grammatical differences between 
                    the NENA varieties spoken in Urmiya, as well as for comparison with other NENA dialects.
                </p>

                <p className="main-text paragraph2">
                    You can also use a <Link to="/corpus">Corpus</Link> created within the framework of our project.
                </p>
            </div>

            <div className="b-cite">
                <div className="cite">
                    <span className="cite-text">How to cite: <span className="smaller">Zabelina et al. 2022– — E. Zabelina, M. Ovsjannikova, and S. Say. Sound dictionary of NENA varieties spoken in Russia. 2022–. (Available online at <Link to="/dictionary">https://nena-dict.ru/dictionary</Link>, accessed on { month }, { day }, { year }).</span></span>
                </div>
            </div>
        </div>
    );
}