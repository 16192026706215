import { Link, Outlet } from "react-router-dom";

import './MainPage.css';

import Header from '../header/Header';
import Footer from '../footer/Footer';

const monthStr = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
];

export default function MainPage() {
    return (
        <main>
            <Header title={ "NENA varieties spoken in Russia" } path={ "/" } />
            <Outlet />
            <Footer />
        </main>
    );
}

export function MainInfo() {
    const date = new Date();
    const day = date.getDate();
    const month = monthStr[date.getMonth()];
    const year = date.getFullYear();

    return (
        <div className="general-page">
            <div className="text">
                <p className="main-text paragraph1">
                    This webpage displays results of a fieldwork-based research on Northeastern Neo-Aramaic (NENA) varieties spoken in Russia. 
                    These data have been gathered in the village of Urmiya, Krasnodar Krai, by a group of researchers coordinated at the RAS Institute for Linguistic Studies in St. Petersburg. 
                    Our field research commenced in 2019, and in 2020-2022 
                    it was supported by a grant from the Russian Foundation for Basic Research.
                </p>

                <p className="main-text paragraph1">
                    Urmiya is the only settlement in Russia where the majority of population are ethnic Assyrians. 
                    While most Assyrians in Urmiya are speakers of the Urmi variety of NENA, the dialectal composition of Urmiya 
                    is very complex and, until recently, remained almost unexplored. Some of the NENA varieties in Urmiya have not been previously described. 
                    Besides, the local varieties are endangered to varying degrees. 
                    Our project aims at the documentation of the NENA varieties spoken in Urmiya. 
                    There are two online resources that have been created within the general framework of our endeavour:
                    <ul>
                        <li><Link to="/corpus">Corpus of NENA varieties spoken in Russia</Link></li>
                        <li><Link to="/dictionary">Sound dictionary of NENA varieties spoken in Russia</Link></li>
                    </ul>
                </p>
            </div>
        </div>
    );
}