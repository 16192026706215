import { BsVolumeDown } from "react-icons/bs";

import "./Audio.css";

const URL = `https://${window.location.hostname}:8000`;

export default function Audio({ link, audioButton }) {
    function sound() {
        audioButton.setAttribute("src", `${URL}/audio/${link}`);
        audioButton.play();
    }

    return (
        <div className="sound" onClick={ sound }>
            <BsVolumeDown />
        </div>
    );
}