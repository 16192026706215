import axios from "axios";
import { useState, useEffect } from "react";
import "./TextPage.css";

export function DiagForms() {
    return (
        <div className="text-page">
            <div className="text-page-header">
                <span>Diagnostic forms</span>
            </div>

            <div className="text-page-content">
                <div className="paragraph">
                    <h1>Overview</h1>
                    <p>
                        <span>NENA displays a relatively complex and irregular morphological system, especially in case of verbs. Generally speaking, inflectional forms are not always predictable from the base form of the word. For this reason, we registered from one to three “diagnostic forms” for every lexeme, depending on its part of speech.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>Nouns</h1>
                    <p>
                        <span>For nouns, we registered both the basic (non-possessed) singular form and the plural form, e.g., +óyma ‘dress’ vs. +oymáyə ‘dresses’ for Speaker 1. The singular form is viewed as the dictionary form here, in [Khan 2016], and elsewhere.</span>
                        <span>Plural formation in the NENA varieties of Urmiya is complex and irregular. In many cases, our consultants reported two possible plural forms for a given noun, e.g. bə́rca ‘knee’ vs. bərcácə or bərcaváy ‘knees’ for Speaker 2. An alternative plural form, if registered, is displayed in the “Plural 2” column of the dictionary. No more than two plural forms were registered for a noun, even if the speaker reported further alternatives (a situation that arose rarely). We also elicited information on the gender of individual nouns in our consultants’ idiolects, using sentences where the nouns in question are combined with agreeing modifiers or predicates.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>Verbs</h1>
                    <p>
                    <span>Unlike other parts of speech, the verb in NENA displays a rich system of inflected morphological forms. The majority of inflected forms can be derived using fairly regular operations based on one of the verb’s stems. For example, subjects and sometimes objects are indexed on the verb by suffixes and/or clitics. The shapes of subject and object markers vary depending on the specific TAM-form of the verb and display fusional alterations, but overall, they are not verb specific. By contrast, formation of stems is templatic, it depends on the verb’s inflectional class (called pattern) and displays some verb-specific idiosyncrasies. For many verbs, one has to memorize its basic stems in order to derive all the inflected forms. Not surprisingly, verb stem formation varies significantly between dialects and sometimes between idiolects.</span>
                        <span>For these reasons, we tried to include three forms of each verb in our dictionary. These forms showcase the so-called Present, Past and Progressive stems (at least two other stems that are necessary to build all the inflected forms, viz. the Resultative stem and the Imperative stem, remain uncovered by the dictionary).</span>
                    </p>
                </div>

                <div className="sub-paragraph">
                    <h1>Present stem</h1>
                    <p>
                        <span>The Present stem without further TAM markers is used in the so-called Present tense forms. The same stem can also be used in combination with some TAM prefixes or suffixes, such as, e.g., the Future tense prefix  bət-. The subject in all these forms is indexed by the so-called S-suffixes. The 3rd person singular masculine S-suffix is zero. Thus, the bare Present stem coincides with the 3rd person singular masculine Present tense form. This form is used as the dictionary form of the verb here, in [Khan 2016], and elsewhere, e.g., pátəx ‘to open’.</span>
                        <span>Contrary to what its name implies, Present tense forms are rarely used in reference to actual events taking place at the moment of speech. These forms usually denote unreal situations, most notably in complement clauses. As a consequence, we mainly used contexts such as ‘The boy wants to V’ in order to elicit the necessary form. Alternatively, we elicited negated forms referring to future events, e.g. le +máddər [NEG return.PRS.SS.3M] '(He) won't return’. It was sometimes impossible to extract the stem without the negation marker le, so that the negation marker is also included in the sound file given in the database.</span>
                    </p>
                </div>

                <div className="sub-paragraph">
                    <h1>Past tense</h1>
                    <p>
                        <span>The Past stem without further TAM markers is used in the basic perfective-type finite form. Importantly, the subject is obligatorily indexed by the so-called L-suffixes, which are always overt. As a consequence, the bare Past tense stem never occurs in speech. For our dictionary, we elicited 3rd person plural forms as forms representing the Past tense stem.</span>
                        <span>For example, we elicited the form ptə́xlun ‘(they) opened’ from Speaker 1, where ptəx is the actual form of the stem, and -lun is the 3rd person plural L-suffix. 3rd person plural L-suffixes display additional variation between -lun and -le, and our dictionary makes it possible to see how these variants are distributed among the dialects (speakers).</span>
                    </p>
                </div>

                <div className="sub-paragraph">
                    <h1>Progressive stem</h1>
                    <p>
                        <span>The Progressive stem without further TAM markers is used in the Present progressive form, which is the main way to refer to situations taking place at the moment of speech. The subject in these forms is indexed by an inflected copula, which is never realized as zero and is normally attached to the verb.</span>
                        <span className="no-indent">{ "(1) axún-i ptáx=əl +tárra\nbrother-P.1SG open.PROG=3M door(M)\n‘My brother is opening the window’." }</span>
                        <span>Fusional processes that take place at morphemic boundaries obfuscate the actual form of the Progressive stem. For example, the Progressive stem of the verb ‘to open’ in (1) is ptaxa, but its combination with the 3rd person singular masculine copula ilə results in the form ptax=ələ, where the final vowel of the stem is no longer visible.</span>
                        <span>For this reason, we tried to elicit the Progressive stem in contexts where the stem is not followed by the copula. Typically, we used negated sentences, where the independent negative copula is used:</span>
                        <span className="no-indent">{ "(2) axún-i lel ptáxa +tárra\nbrother-P.1SG NEG.3M open.PROG door(M)\n‘My brother is not opening the window’." }</span>
                        <span>This type of context makes it possible to elicit the Progressive stem in its full form, even though as a result many Progressive stem entries in the dictionary contain the negative copula.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>Adjectives</h1>
                    <p>
                        <span>For adjectives, the dictionary contains the masculine (singular) and the feminine (singular) forms, as used in the position of modifiers to nouns. Plural forms of adjectives are not shown in the dictionary, but they can be easily derived based on the two forms provided in the dictionary.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>Other</h1>
                    <p>
                        <span>The residual category “Other” includes all words that are not nouns, verbs or adjectives. We do not indicate their part of speech, i.e. whether they are adverbs, pronouns, numerals, etc. Morphologically, all these words are similar in that they are not inflected.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>References</h1>
                    <p>
                        <span className="no-indent">Khan 2016 – Geoffrey Khan. The Neo-Aramaic dialect of the Assyrian Christians of Urmi. Leiden, Boston: Brill, 2016.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export function SpellingConv() {
    return (
        <div className="text-page">
            <div className="text-page-header">
                <span>Spelling conventions</span>
            </div>

            <div className="text-page-content">
                <div className="paragraph">
                    <h1>Transcription conventions</h1>
                    <p>
                        <span>Audio recordings of inflected word forms included in this sound dictionary are supplemented by transcriptions. These transcriptions generally follow the conventions adopted in [Khan 2016]. In particular, suprasegmental emphasis is marked by the symbol “+” at the beginning of the word. The position of stress in polysyllabic forms is indicated by the acute accent. As in other materials prepared within the framework of our project, there are several minor deviations from Geoffrey Khan’s transcription system, as summarized in the following table.</span>
                    </p>
                </div>

                <table>
                    <thead>
                        <tr>
                            <td>Khan 2016</td>
                            <td>The sound dictionary of NENA</td>
                            <td>Comment</td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>ʾ</td>
                            <td></td>
                            <td className="thin">glottal stop (absent in the idioms studied?)</td>
                        </tr>

                        <tr>
                            <td>p̌</td>
                            <td>ṗ</td>
                            <td className="thin">tense unvoiced labial stop</td>
                        </tr>

                        <tr>
                            <td>ṱ</td>
                            <td>ṭ</td>
                            <td className="thin">tense unvoiced dental stop</td>
                        </tr>

                        <tr>
                            <td>c̭</td>
                            <td>c̣</td>
                            <td className="thin">tense unvoiced palatal stop / affricate / velar stop</td>
                        </tr>

                        <tr>
                            <td>ɟ</td>
                            <td>g</td>
                            <td className="thin">voiced palatal (?) affricate</td>
                        </tr>

                        <tr>
                            <td>k̭</td>
                            <td>k</td>
                            <td className="thin">unvoiced (post?)-velar stop</td>
                        </tr>

                        <tr>
                            <td>č̭</td>
                            <td>č̣</td>
                            <td className="thin">tense unvoiced palato-alveolar (?) affricate</td>
                        </tr>

                        <tr>
                            <td>j</td>
                            <td>ǧ</td>
                            <td className="thin">voiced palato-alveolar (?) affricate</td>
                        </tr>

                        <tr>
                            <td>ġ</td>
                            <td>ɣ</td>
                            <td className="thin">voiced (post?)-velar fricative</td>
                        </tr>
                    </tbody>
                </table>

                <div className="paragraph">
                    <p>
                        <span>As the data of the sound dictionary clearly indicate, the actual phonetic realizations recorded in Urmiya can significantly diverge from the transcriptions provided in [Khan 2016]. The documentation of phonetic variation between NENA dialects spoken in Urmiya and possibly even between individual speakers is one of the main objectives of our dictionary. However, at this stage we are not in a position to interpret phonologically the differences we observe and to propose phonological inventories for the dialects and idiolects under investigation. For this reason, we used the strategy of moderate unification when transcribing our materials: we transcribed the forms we registered in the form that is expected based on [Khan 2016] unless we were certain that the particular speaker employs the form that is phonologically different. By contrast, our transcriptions faithfully represent the variation in the morphological build-up of inflected forms as well as lexical variation.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>References</h1>
                    <p>
                        <span className="no-indent">Khan 2016 – Geoffrey Khan. <i>The Neo-Aramaic dialect of the Assyrian Christians of Urmi.</i> Leiden, Boston: Brill, 2016.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export function Speakers() {
    const [ data, setData ] = useState(null);

    useEffect(() => {
        axios.get("/speakers")
            .then((res) => {
                setData(res.data);
            });
    }, []);

    if (data === null) return undefined;

    return (
        <div className="text-page">
            <div className="text-page-header">
                <span>Idioms and speakers</span>
            </div>

            <div className="text-page-content">
                <div className="paragraph">
                    <h1>The dialectal composition of Urmiya</h1>
                    <p>
                        <span>The village of Urmiya, Krasnodar Krai, is the only settlement in the Russian Federation where the majority of the population is composed of ethnic Assyrians. Assyrians descend from Christian communities of the Middle East who spoke North Eastern Neo-Aramaic (NENA) idioms. For the sake of simplicity, we refer to these idioms as “dialects”, but it is not entirely clear whether they belong to the same “language”.</span>
                        <span>The dialectal situation in Urmiya is complex, see [Sarkisov (submitted)]. It is clear that the dominant dialect of Urmiya, both in terms of number of speakers and sociolinguistic status, is the Christian Urmi dialect. This dialect was originally spread in the Urmi plain in northwestern Iran. It was also used as the basis for the Standard Assyrian language.</span>
                        <span>However, the speakers of the Urmi dialect, or urməžnáya, do not form a linguistically homogeneous community in Urmiya. Sociolinguistically, they can be divided into two large groups. The first group is formed by the descendants of the early settlers who came to Urmiya around the time when Urmiya was founded in 1924. We refer to this group as “Old” urməžnáya.</span>
                        <span>The second (and larger) group of urməžnáya is formed by the speakers of the Urmi dialect who moved to Urmiya from Armenia or Georgia after the dissolution of the Soviet Union. We refer to this group as “New” urməžnáya.</span>
                        <span>There is also a noticeable presence of Non-Urmi NENA speakers in Urmiya, see [Ovsjannikova, Zabelina, and Kozhanov (in print)] for details. Among these, the majority is locally referred to as šapətnáya. The members of this group are mostly descendants of the early settlers of Urmiya whose origin can be traced back to mountainous areas in the southwestern part of present-day Turkey.</span>
                        <span>One of the main goals of our project is to empirically assess similarities and differences between the NENA varieties spoken in Urmiya. We address this problem in a bottom-up fashion, starting with exploring the lexicon of several idiolects, which we hope can eventually elucidate the dialectal composition of Urmiya at large.</span>
                    </p>
                </div>

                <div className="paragraph">
                    <h1>Speakers</h1>
                </div>

                { data.map((elem) => {
                    return (
                        <div key={ elem.code } className="sub-paragraph">
                            <h1>{ `Speaker ${elem.position} (speaker’s code is ${elem.code})` }</h1>
                            <p>
                                <span>{ elem.data }</span>
                            </p>
                        </div>
                    );
                }) }

                <div className="paragraph">
                    <h1>References</h1>
                    <p>
                        <span className="no-indent">Sarkisov (submitted) – Саркисов, И. В. Социолингвистическая ситуация в селе Урмия [Sociolinguistic landscape of the village of Urmiya]. Submitted to Acta Linguistica Petropolitana.</span>
                        <span className="no-indent">Ovsjannikova, Zabelina, and Kozhanov (in print) – Maria Ovsjannikova, Elizaveta Zabelina, and Kirill Kozhanov. Non-Urmi Northeastern Neo-Aramaic varieties in the village of Urmiya, Russia.  Part 1: Sociolinguistic overview and phonetic features. Accepted for publication in Iran and the Caucasus.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}