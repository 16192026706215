import axios from "axios";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./Login.css";

import Admin from "../admin/Admin";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"

import { Context } from "../../App";

export default function Login() {
    const { isAuth, setIsAuth } = useContext(Context);

    const [ log, setLog ] = useState("");
    const [ pass, setPass ] = useState("");

    const [ hidePass, setHidePass ] = useState(true);
    const [ err, setErr ] = useState(false);

    const navigate = useNavigate();

    return (
        <div className="login">
            <div className="login-wd">
                <label>
                    <input
                        type="text"
                        spellCheck="false"
                        placeholder="Login"
                        onChange={ ({ target }) => setLog(target.value) }
                    />
                </label>
                <label>
                    <input
                        type={ hidePass
                        ? "password"
                        : "text"
                        }
                        spellCheck="false"
                        placeholder="Password"
                        onChange={ ({ target }) => setPass(target.value) }
                    />

                    <div
                        className="hide-pass"
                        onClick={ () => setHidePass(!hidePass) }
                    >
                        { hidePass
                        ? <AiOutlineEyeInvisible />
                        : <AiOutlineEye />
                        }
                    </div>
                </label>

                <div
                    className="login-btn"
                    onClick={ () => {
                        axios.post("/login", {
                            login: log,
                            password: pass
                        }).then(() => {
                            setIsAuth();
                            navigate("/dictionary/admin");
                        }).catch(() => {
                            setErr(true);
                        });
                    } }
                >
                    login
                </div>

                { err
                ? <span className="err">Invalid login or password.</span>
                : undefined
                }
            </div>
        </div>
    );
}
