import { Link } from 'react-router-dom';
import './Thead.css';

import { FaSort } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa";

export default function Thead({ onChange: filtersState, headers, currentSort }) {
    function Row({ data }) {
        function Cell({ data }) {
            const iconsArr = [<FaSort />, <FaSortDown />, <FaSortUp />];

            return (
                <td
                    colSpan={ data.size !== null ? data.size : 1 }
                    type={ data.type !== null ? data.type : undefined }
                >
                    <div
                        className={ [data.type] in filtersState && !filtersState[data.type].isActive
                        ? "td-child hidden"
                        : "td-child" 
                        }
                    >
                        <span className={ "cell-text-content" } dangerouslySetInnerHTML={{ __html: data.content }}></span>
                        { data.sort !== null
                        ? <Link
                            className={ "sortButt" }
                            to={ `?get=rows&sort=${ data.sort.indexOf(currentSort) !== -1
                            ? data.sort[(data.sort.indexOf(currentSort) + 1) % 3]
                            : data.sort[1]
                            }&page=1`}
                        >
                            { data.sort.includes(currentSort)
                            ? iconsArr[data.sort.indexOf(currentSort)]
                            : iconsArr[0]
                            }
                        </Link> 
                        : undefined
                        }
                    </div>
                </td>
            );
        }

        return (
            <tr>
                { data.cells.map( (elem, index) => (
                    <Cell data={ elem } key={ index } />
                )) }
            </tr>
        );
    }

    return (
        <thead>
            { headers.map( (elem, index) => (
                <Row data={ elem } key={ index } />
            )) }
        </thead>
    );
}