import { Link } from "react-router-dom";

const monthStr = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
];

export default function General() {
    const date = new Date();
    const day = date.getDate();
    const month = monthStr[date.getMonth()];
    const year = date.getFullYear();

    return (
        <div className="general-page">
            <div className="text">
                <p className="main-text paragraph1">
                    This corpus contains texts in several Northeastern Neo-Aramaic (NENA) varieties spoken Russia. Most recordings have been made during field trips to the village of Urmiya, Krasnodar Krai, the only settlement in Russia where ethnic Assyrians constitute the majority of population.
                </p>

                <p className="main-text paragraph2">
                    To search the corpus, press <a href="/corpus/search">here</a>.
                </p>

                <p className="main-text peragraph2">
                    You can also use a <Link to="/dictionary">Sound dictionary</Link> created within the framework of our project.
                </p>
            </div>

            <div className="b-cite">
                <div className="cite">
                    <span className="cite-text">How to cite: <span className="smaller">Ovsjannikova, Maria, Kirill Kozhanov, Natalia Logvinova, Alexey Lyavdansky, Alina Russkikh, Sergey Say, Varvara Shuvalova, Elena Shvedova, and Elizaveta Zabelina. 2022–. Corpus of NENA varieties spoken in Russia. Available online at <Link to="/corpus">https://nena-dict.ru/corpus</Link>.</span></span>
                </div>
            </div>
        </div>
    );
}