import { useState, useEffect, useRef } from "react";

export default function Input({ value, setState, disabled }) {
    const inpRef = useRef();

    useEffect(() => {
        inpRef.current.value = value;
    }, []);

    return (
        <input
            ref={ inpRef }
            className={ disabled
            ? "disabled"
            : ""
            }
            disabled={ disabled }
            onChange={ ({ target }) => setState(target.value) }
        />
    );
}