import { Outlet } from "react-router-dom";

import './Main.css';

import Header from '../header/Header';
import Nav from '../nav/Nav';
import Footer from '../footer/Footer';

export default function Main() {
    return (
        <main>
            <Header title={ "Sound dictionary of NENA varieties\nspoken in Russia" } path={ "/dictionary" } />
            <Nav />
            <Outlet />
            <Footer />
        </main>
    );
}